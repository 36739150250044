<template>
  <v-container>
    <v-row>
      <v-col cols="2">
        <h2>事業所会員一覧</h2>
      </v-col>
      <v-col cols="3">
        <v-radio-group
          v-model="searchCondition.listType"
          dense
          row
          :disabled="loading"
          @change="loadList()"
        >
          <v-radio
            label="会員"
            :value="0"
            checked
          ></v-radio>
          <v-radio
            label="非会員"
            :value="1"
            class="ml-minus"
          ></v-radio>
          <v-radio
            label="閉鎖廃止"
            :value="2"
            class="ml-minus"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="5">
        <v-layout wrap>
          <v-checkbox
              class="mr-3 ml-minus" label="会員"
              v-model="searchCondition.divCompany"
              :true-value="1"   :false-value="0"
              :disabled="loading"
              @change="loadList()"
              dense
              small/>
          <v-checkbox
              class="mr-3 ml-minus" label="事業所会員Ⅰ"
              v-model="searchCondition.divIndustry1"
              :true-value="1"   :false-value="0"
              :disabled="loading"
              @change="loadList()"
              dense />
          <v-checkbox
              class="mr-3 ml-minus" label="事業所会員Ⅱ"
              v-model="searchCondition.divIndustry2"
              :true-value="1"   :false-value="0"
              :disabled="loading"
              @change="loadList()"
              dense />
          <v-checkbox
              class="mr-3 ml-minus" label="事業所会員Ⅲ"
              v-model="searchCondition.divIndustry3"
              :true-value="1"   :false-value="0"
              :disabled="loading"
              @change="loadList()"
              dense />
          <v-checkbox
              class="mr-3 ml-minus" label="賛助"
              v-model="searchCondition.divSupporter"
              :true-value="1"   :false-value="0"
              :disabled="loading"
              @change="loadList()"
              dense />
          <v-checkbox
              class="mr-3 ml-minus"
              label="冷事"
              v-model="searchCondition.divFrozen"
              :true-value="1"   :false-value="0"
              :disabled="loading"
              @change="loadList()"
              dense />
        </v-layout>
      </v-col>

      <v-col cols="1">
      </v-col>
      <v-col v-if="attribute == 2" cols="1" class="center">
        <v-btn class="primary pl-8 pr-8" :height="30" :width="140" :disabled="loading" href="/member_update_history">
          変更履歴画面
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="pl-5" style="margin-top: 0">
      <v-col cols="8" >
        <table class="member-table">
          <tr>
            <!-- <td>会員: {{filterItems.length}}</td> -->
            <td>会員企業: {{memberList1.length}}</td>
            <td>事業所会員Ⅰ: {{memberList2.length}}</td>
            <td>事業所会員Ⅱ: {{memberList3.length}}</td>
            <td>事業所会員Ⅲ: {{memberList4.length}}</td>
            <td>賛助会員: {{memberList5.length}}</td>
            <td>日冷協会員: {{memberList6.length}}</td>
          </tr>
        </table>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="3"  class="mt-0">
        <v-text-field label="検索"
              dense
              v-model="searchCondition.searchString"
              :disabled="loading"
              :loading="loading"
              class="mt-0"
        ></v-text-field>
        <!-- <v-btn class="primary" :height="40" :width="100" :disabled="loading" @click="search()">検索</v-btn> -->
      </v-col>
      <v-col cols="1">
        <v-btn v-if="attribute == 2" class="primary" :height="30" :width="140" :disabled="loading" @click="goto_detail('new', '')">
          <v-icon left>mdi-plus-circle</v-icon>
          新規会員登録
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="memberList"
          :search="searchCondition.searchString"
          ref="ref"
          :loading="loading"
          dense
          :mobile-breakpoint="0"
          :disable-pagination='true'
          hide-default-footer
          :fixed-header='true'
          multi-sort
          height="calc(100vh - 260px)"
          locale="ja-jp"
          loading-text="読込中"
          no-data-text="データがありません。"
          class="sticky_table"
          style="margin-top: -10px;"
        >
          <template v-slot:item="{ item }">
            <tr :key="item.memberNo" @dblclick="goto_detail('edit', item.memberNo)" >
              <td>{{ item.memberNo }}</td>
              <td>{{ item.companyId}}</td>
              <td>{{ item.companyName }}</td>
              <td>{{ item.officeName }}</td>
              <td class="center">{{ dispSymbol(item.division_company, '●')}}</td>
              <td class="center">{{ dispSymbol(item.division_industry1)}}</td>
              <td class="center">{{ dispSymbol(item.division_industry2)}}</td>
              <td class="center">{{ dispSymbol(item.division_industry3)}}</td>
              <td class="center">{{ dispSymbol(item.division_supporter)}}</td>
              <td>{{ item.association_name}}</td>
              <td class="center">{{ dispSymbol(item.division_frozen)}}</td>
              <td>{{ item.frozen_association_name}}</td>

              <td>{{ replacePrefIdToPrefName(item.prefId) }}</td>
              <td>{{ item.address }}</td>
              <td>{{ item.districtName }}</td>
              <td nowrap class="center ">
                <v-btn small class="primary" :height="20" :width="60"
                      :disabled="loading"
                      @click="goto_detail('edit', item.memberNo)"
                >
                  {{ attribute == 2 ? "編集" : "表示" }}
                </v-btn>
                <!--
                <v-btn v-if="attribute == 2" small class="secondary ml-2" :height="20" :width="60"
                      :disabled="loading"
                      @click="goto_detail('delete', item.memberNo)"
                >
                  削除
                </v-btn>
                -->
              </td>
              <td></td>
            </tr>
          </template>

          <!-- <template v-slot:footer>
            <v-row class="mt-2 pl-5">
              <v-col cols="2">
                会員: {{filterItems.length}}
              </v-col>
              <v-col cols="2">
              </v-col>
              <v-col cols="8">
                <span class="mr-5">会員企業: {{memberList1.length}}</span>
                <span class="mr-5">事業所会員Ⅰ: {{memberList2.length}}</span>
                <span class="mr-5">事業所会員Ⅱ: {{memberList3.length}}</span>
                <span class="mr-5">事業所会員Ⅲ: {{memberList4.length}}</span>
                <span class="mr-5">賛助会員: {{memberList5.length}}</span>
                <span class="mr-5">日冷協会員: {{memberList6.length}}</span>
              </v-col>
            </v-row>
          </template> -->

        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Mixin from "../mixins/const.js";
import Member from "../model/member";
import Message from "../Lib/Message";

import Debug from "../Lib/Debug";

export default {
  mixins: [Mixin],

  data: () => ({
    loading: false,

    // テーブルヘッダー
    headers: [
      { text: "会員ID", align: "center", sortable: true, value: "memberNo", width: "80px" },
      { text: "登録番号", align: "center", sortable: true, value: "companyId", width: "80px" },
      { text: "会社名", align: "left", sortable: true, value: "companyName", width: "240px" },
      { text: "事業所名", align: "left", sortable: true, value: "officeName", width: "240px" },

      { text: "会員企業", align: "center", sortable: true, value: "division_company", width: "40px" },
      { text: "事業所会員Ⅰ ", align: "center", sortable: true, value: "division_industry1", width: "50px" },
      { text: "事業所会員Ⅱ ", align: "center", sortable: true, value: "division_industry2", width: "50px" },
      { text: "事業所会員Ⅲ ", align: "center", sortable: true, value: "division_industry3", width: "50px" },
      { text: "賛助会員", align: "center", sortable: true, value: "division_supporter", width: "40px" },
      { text: "冷蔵倉庫協会", align: "left", sortable: true, value: "association_name", width: "180px" },
      { text: "冷事会員", align: "center", sortable: true, value: "division_frozen", width: "40px" },
      { text: "冷凍事業協会", align: "left", sortable: true, value: "frozen_association_name", width: "180px" },

      { text: "都道府県", align: "center", sortable: true, value: "prefName", width: "80px" },
      { text: "住所", align: "left", sortable: true, value: "address", width: "200px" },
      { text: "地区", align: "left", sortable: true, value: "district", width: "80px" },
      { text: "", sortable: false, width: "200px" },
      { text: "", sortable: false },
    ],

    memberList: [], // 会員データ一覧

    attribute: 0,


    // 検索条件
    searchCondition: {
      screenType: 'MemberList',
      searchString: "",
      associationId: null,
      frozenAssociationId: null,
      companyId: null,
      listType: 0,
      divCompany: 0,
      divIndustry1: 1,
      divIndustry2: 0,
      divIndustry3: 0,
      divSupporter: 0,
      divFrozen: 0,
    },
  }),

  // ライフサイクルフック
  created: function () {
    // 属性による検索条件の設定
    this.attribute = this.$store.getters.getAttribute;
    if(this.attribute == 0) {
      // 事業所ユーザは自分の会社に所属する事業所データのみ
      this.searchCondition.companyId = this.$store.getters.getCompanyId;
    }
    if(this.attribute == 1) {
      // 都道府県協会ユーザは自協会に所属する会社のデータのみ
      this.searchCondition.associationId = this.$store.getters.getAssociation.association_id;
    //   this.searchCondition.frozenAssociationId = this.$store.getters.getAssociation.frozen_association_id;
      this.searchCondition.frozenAssociationId = this.$store.getters.getFrozenAssociation.association_id;
    }
  },

  mounted: async function () {
    const storeSearchCondition = this.$store.getters.getStoreSearchCondition;
    if (storeSearchCondition.screenType == 'MemberList') {
      this.searchCondition = storeSearchCondition;
    }
    Debug.log2("searchCondition", this.searchCondition);

    this.loadList();
  },

  methods: {
    /** 表示更新 */
    async loadList() {
      Debug.log("function[loadList]");
      this.loading = true;

      // DBから会員一覧取得
      try {
Debug.log("SearchCondition ->");
Debug.log(this.searchCondition);
        const res = await Member.search(this.searchCondition);
        this.memberList = res.data;

        this.$store.commit('setStoreSearchCondition', this.searchCondition);
      } catch (error) {
        Message.err(error, "会員一覧データを取得できませんでした");
      }

      Debug.log(this.memberList);
      this.loading = false;
    },

    /** 詳細ページに遷移 */
    goto_detail(mode, memberNo, blenk = false) {
      Debug.log("function[goto_detail]");

      if (mode == 'edit' && this.attribute < 2) {
        mode = 'disp';
      }

      this.$store.commit("setMode", mode); // 新規 or 編集 or 削除をstoreに保存
      this.$store.commit("setEditId", memberNo);

      if (blenk) {
        // 別タブで開く
        const resolvedRoute = this.$router.resolve("/member_edit/");
        window.open(resolvedRoute.href, "_blank");
      } else {
        // 同タブで開く
        this.$router.push("/member_edit/");
      }
    },

    clickRow(item) {
      Debug.log("function[clickRow]");

      this.goto_detail('edit', item.memberNo);
    },

    dispSymbol(val, symbol = '○') {
      if (val == 0) {
        return ''
      } else {
        return symbol
      }
    },
  },

  computed: {
    filterItems() {
      if (this.memberList.length > 0) {
        return this.$refs.ref.$children[0].filteredItems;
      } else {
        return [];
      }
    },
    memberList1() {
      return this.filterItems.filter(item => item.division_company == "1");
    },
    memberList2() {
      return this.filterItems.filter(item => item.division_industry1 == "1");
    },
    memberList3() {
      return this.filterItems.filter(item => item.division_industry2 == "1");
    },
    memberList4() {
      return this.filterItems.filter(item => item.division_industry3 == "1");
    },
    memberList5() {
      return this.filterItems.filter(item => item.division_supporter == "1");
    },
    memberList6() {
      return this.filterItems.filter(item => item.division_frozen == "1");
    },
  },

};
</script>

<style scoped>
.disablecolor {
  background: #bbbbbb;
}
.flex {
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: center;
}

.v-input--radio-group {
  margin: 0;
}

/* 列固定、他 */
.sticky_table >>> th,
.sticky_table >>> td {
	min-width: 80px;
  padding: 0 5px  !important;
  z-index: 1;
}

/* ここから固定用のコード */
.sticky_table >>> th:nth-child(1) {
	left: 0;
  z-index: 5;
}
.sticky_table >>> th:nth-child(2) {
	left: 80px;
  z-index: 5;
}
.sticky_table >>> th:nth-child(3) {
	left: 160px;
  z-index: 5;
}
.sticky_table >>> th:nth-child(4) {
	left: 400px;
  z-index: 5;
}

.sticky_table td:nth-child(1) {
	position: -webkit-sticky;
	position: sticky;
  background-color: white;
	left: 0;
  width: 80px;
}
.sticky_table td:nth-child(2) {
	position: -webkit-sticky;
	position: sticky;
  background-color: white;
	left: 80px;
  width: 80px;
}
.sticky_table td:nth-child(3) {
	position: -webkit-sticky;
	position: sticky;
  background-color: white;
	left: 160px;
  width: 240px;
}
.sticky_table td:nth-child(4) {
	position: -webkit-sticky;
	position: sticky;
  background-color: white;
	left: 400px;
  width: 240px;
}

/* hover の設定を行う */
.sticky_table tr:hover {
	background-color: var(--v-table_hover-base);
}
.sticky_table tr:hover > td {
	background-color: var(--v-table_hover-base);
}

/* 会員数 テーブル  */
.member-table {
  width: 100%;
  border-collapse: collapse;
}

.member-table td {
  border:solid 1px #555555;
  text-align: center;
  padding: 5px 3px 3px 5px;
  font-size: 16px;
  font-weight: 600;
}

.v-input >>> .v-label {
  font-size: 15px;
}

.ml-minus {
  margin-left: -8px !important;
}
</style>
